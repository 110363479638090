.section-total {
  display: flex;
  justify-content: flex-end;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 20px;
}
.printIcon {
  font-size: xx-large;
  color: "black";
}
.title-text {
  color: "black";
  font-weight: bold;
}
.container {
  margin-top: 20px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.denyOrderButton {
  height: 50px;
  width: auto;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.confirmDsc {
  margin-top: 30px;
  text-align: center;
}
.confirmOrderButton {
  height: 50px;
  width: auto;
  margin-right: 10px;
}
.rejectOrderButton {
  height: 50px;
  width: auto;
}
.toast-position {
  margin-top: 60px;
}
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.errorText {
  font-weight: 500;
  font-size: 20px;
}
.main {
  display: flex;
}
.printerConainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.EditBtn {
  background-color: #e6e6e6;
  outline: none;
  border: none;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
}
.pickup-input {
  border: 1px solid grey;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 4px;
}
